import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffb81d',
      light: '#dddddd',
    },
    secondary: {
      main: '#dd4b39',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        '&$disabled': {
          backgroundColor: 'rgba(255, 184, 29, 0.3)', // A lighter version of primary.main
          color: 'rgba(0, 0, 0, 0.3)', // A light gray color for text
        }
      },
    },
  },
});

export default theme;

import React, { useState } from 'react';
import noop from 'lodash/noop';

import { nanoid } from 'core/util/nanoid';
import ToastMessages from 'core/components/ToastMessages';

interface ToastOptions {
  id?: string;
  type?: 'success' | 'info' | 'warning' | 'error';
  position?:
    | 'top center'
    | 'bottom center'
    | 'top left'
    | 'top right'
    | 'bottom left'
    | 'bottom right';
  duration?: number;
}

interface ToastContextState {
  show: (message: string, options?: ToastOptions) => string;
  dismiss: (toastId?: string) => void;
}

interface IToast extends Required<ToastOptions> {
  open: boolean;
  message: string;
}

interface Props {
  children: React.ReactNode;
}

export const DEFAULT_DURATION = 5000;
export const DEFAULT_POSITION = 'top center';
export const DEFAULT_TOAST_TYPE = 'info';

export const ToastContext = React.createContext<ToastContextState>({
  show: noop,
  dismiss: noop,
} as ToastContextState);

export default function ToastProvider({ children }: Props) {
  const [toasts, setToasts] = useState<Map<string, IToast>>(new Map());

  const show = React.useCallback<ToastContextState['show']>(
    (message, options) => {
      const { duration, type, position, id } = options || {};
      const toastId = id || nanoid(10);

      if (process.env.NODE_ENV !== 'production') {
        if (!message) {
          console.warn(
            `The toast will not be displayed because the message is empty. Toast id: ${toastId}`
          );
        }
      }

      setToasts((prevToasts) => {
        const newToasts = new Map(prevToasts);
        newToasts.set(toastId, {
          open: !!message,
          id: toastId,
          message,
          type: type || DEFAULT_TOAST_TYPE,
          position: position || DEFAULT_POSITION,
          duration: duration || DEFAULT_DURATION,
        });
        return newToasts;
      });
      return toastId;
    },
    []
  );

  const dismiss = React.useCallback<ToastContextState['dismiss']>((toastId) => {
    if (typeof toastId === 'string') {
      setToasts((prevToasts) => {
        if (prevToasts.delete(toastId)) {
          return new Map(prevToasts);
        }
        return prevToasts;
      });
    } else {
      setToasts(new Map());
    }
  }, []);

  const value = React.useMemo<ToastContextState>(() => ({ show, dismiss }), []);

  return (
    <ToastContext.Provider value={value}>
      {children}
      <ToastMessages toasts={Array.from(toasts.values())} onClose={dismiss} />
    </ToastContext.Provider>
  );
}

function useToast() {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}

export { ToastProvider, useToast };

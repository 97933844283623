/**
 * Genera el identificador de la cuenta a partir del nodo-raíz y la base de datos de Firebase
 * @param {rootNode} Nodo raíz de la aplicación
 */
 function getAccountID(rootNode,userDb) {
   
    try {
      let cuentasDB= userDb;

        if (rootNode!==undefined) {
          const rn = rootNode.replace('/', '>');
          cuentasDB = userDb + '@' + rn;
        }
        return cuentasDB;

      } catch (error) {
        console.log(error);
        //error
      }

  }
  
  export default getAccountID;
  
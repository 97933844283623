import React, { createContext, useContext, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from 'firebase/auth';
import {
  getDatabase,
  ref as databaseRef,
  get as getFromDB,
  onValue,
} from 'firebase/database';
import { getStorage, ref as storageRef, listAll, getDownloadURL, getMetadata} from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';

import store from 'store2';

import firebaseSettings from 'core/firebase/settings';

import getAccountID from '../util/getAccountID';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

// Inicializa Firebase
const defaultApp = initializeApp(firebaseSettings, 'default');

const auth = getAuth(defaultApp);
const defaultDb = getDatabase(defaultApp);
const firestoredb = getFirestore(defaultApp);
const functions = getFunctions(defaultApp);
const analytics = getAnalytics(defaultApp);

// Configuración de storage
const storage = getStorage(defaultApp);
const photoStorage = getStorage(
  defaultApp,
  process.env.REACT_APP_PHOTOS_STORAGE
);
const fileStorage = getStorage(
  defaultApp,
  process.env.REACT_APP_FILES_STORAGE
);

// Función para obtener una referencia a un almacenamiento específico
const downloadsStorage = (bucket) => {
  return getStorage(defaultApp, bucket);
};

// Crea el contexto
const FirebaseContext = createContext();

function FirebaseProvider(props) {
  const [user, setUser] = useState(null);
  const [userM, setUserM] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [menuConfig, setMenuConfig] = useState({});

  const [nameAccount, setNameAccount] = useState('');
  const [isInitialized, setInitialize] = useState(false);
  const [userDB, setUserDB] = useState(null);

  const [isNewUserRegistered, setIsNewUserRegistered] = useState(false);

  /**
   * Inicializa la aplicación y configura la base de datos
   * con base al usuario actual.
   * @param {Object} currentUser
   */

  const initialize = async (currentUser) => {
    const lastAccount = store.get('lastAccount');
    try {
      const userRef = databaseRef(defaultDb, `usuarios/${currentUser.uid}`);


      onValue(userRef, (snapshot) => {
        if (!snapshot.exists()) {
          return;
        }

        const userData = snapshot.val();

        if (!userData) {
          setUserM(currentUser);
          setUser(null);
          setInitialize(true);
        }

          const userDB = getCurrentDB(lastAccount ? lastAccount : userData);

          let currentUserAccountID = getAccountID(
            lastAccount ? lastAccount.nodo_raiz : userData.nodo_raiz,
            lastAccount ? lastAccount.base_datos : userData.base_datos
          );

          if (!(currentUserAccountID in userData['cuentas'])) {
            currentUserAccountID = userData['cuentas'][0];
          }

          let userState = {
            ...currentUser,
            profile: userData['cuentas'][currentUserAccountID].perfil,
            userForms: [],
            userDb: userData['cuentas'][currentUserAccountID].base_datos,
            rootNode: userData['cuentas'][currentUserAccountID].nodo_raiz,
            superset_activo:
              userData['cuentas'][currentUserAccountID].superset_activo,
            superset_url:
              userData['cuentas'][currentUserAccountID].superset_url,
            url_calendario:
              userData['cuentas'][currentUserAccountID].url_calendario,
            accounts: userData['cuentas'],
            currentUserAccountID: currentUserAccountID,
          };

          setInitialize(true);
          setUser(userState);
          setUserDB(userDB);

          const nameCurrentAccount =
            userData['cuentas'][currentUserAccountID].nombre;
          setNameAccount(nameCurrentAccount);

          const configRef = userDB.ref('config/');
          onValue(configRef, (configSnapshot) => {
            const dbConfig = configSnapshot.val();

            setMenuConfig({
              menuPDF: dbConfig ? dbConfig.archivos_pdf : false,
              menuAdmForm: administadorFormularios(userState) && true,
              menuadm:
                userData['cuentas'][currentUserAccountID].admin_cuenta === 'Si'
                  ? true
                  : false,
              menuAnalytics: userState.superset_activo ? true : false,
              isCalendarEnabled: dbConfig && dbConfig.exportar_calendario,
              mfa_requerido: dbConfig && dbConfig.mfa_requerido,
              fecha_servidor: dbConfig && dbConfig.fecha_servidor,
            });
          });

      });
    } catch (error) {
      console.log(error);
      // logout();
      // throw new Error(error);
    }
  };

  if (!isInitialized && !user) {
    onAuthStateChanged(auth, async (currentUser) => {
      try {
        if (currentUser) {
          setIsAuthenticated(true);
          if (!isInitialized) {
            await initialize(currentUser);
          }
        } else {
          setIsAuthenticated(false);
          setUser(null);
          setInitialize(true);
        }
      } catch (error) {
        console.error('Error during initialization:', error);
        // Manejo de errores adicional si es necesario
      }
    });
  }

  // Obtiene la base de datos según el usuario actual
  const getCurrentDB = (currentUser) => {
    // Obtener la referencia a la base de datos usando el URL base
    const currentDatabase = getDatabase(
      defaultApp,
      `https://${currentUser.base_datos}.firebaseio.com/`
    );

    // Crear una función que devuelve la referencia adecuada
    const getRef = (requestRef) => {
      if (currentUser.nodo_raiz) {
        return databaseRef(
          currentDatabase,
          `${currentUser.nodo_raiz}/${requestRef}`
        );
      } else {
        return databaseRef(currentDatabase, requestRef);
      }
    };

    return { ref: getRef };
  };

  const handleReauthenticateUser = async () => {
    auth.signOut();
    setUser(null);
    setIsAuthenticated(false);
  };

  //Cambio de cuenta
  const changeAccountAdm = async (currentUser) => {
    store.set('lastAccount', currentUser);

    try {
      let userState = {
        ...user,
        profile: currentUser.perfil,
        userForms: [],
        userDb: currentUser.base_datos,
        rootNode: currentUser.nodo_raiz,
        superset_activo: currentUser.superset_activo,
        superset_url: currentUser.superset_url,
        currentUserAccountID: getAccountID(
          currentUser.nodo_raiz,
          currentUser.base_datos
        ),
      };

      const userDB = await getCurrentDB(currentUser);
      const configRef = userDB.ref('config/');
      const configSnapshot = await getFromDB(configRef);
      const dbConfig = configSnapshot.val();

      const menuConfigAccountSelec = {
        ...menuConfig,
        menuadm: currentUser.admin_cuenta === 'Si' ? true : false,
        menuAdmForm:
          currentUser.admin_cuenta === 'Si' && administadorFormularios(user)
            ? true
            : false,
        menuAnalytics: currentUser.superset_activo,
        menuPDF: dbConfig ? dbConfig.archivos_pdf : false,
        isCalendarEnabled: dbConfig && dbConfig.exportar_calendario,
        mfa_requerido: dbConfig && dbConfig.mfa_requerido,
        fecha_servidor: dbConfig && dbConfig.fecha_servidor,
      };

      let mfaAuthenticated;
      const mfaCookie = Cookies.get('8s9t1');
      if (mfaCookie) {
        const decryptedBytes = CryptoJS.AES.decrypt(
          mfaCookie,
          'mfa_key_authentication'
        );
        mfaAuthenticated = decryptedBytes.toString(CryptoJS.enc.Utf8);
      }

      if (menuConfigAccountSelec.mfa_requerido && !mfaAuthenticated) {
        store.set('lastAccount', currentUser);
        // setUser(null);
        return menuConfigAccountSelec.mfa_requerido;
      } else {
        setUser(userState);
        setUserDB(userDB);
        setMenuConfig(menuConfigAccountSelec);
        return false;
      }
    } catch (error) {
      //error;
    }
  };

  //Construccion de la cuenta base_datos + nodo_raiz
  const accountID = async (currentUser) => {
    try {
      let cuentasDB = currentUser.userDb;

      if (currentUser.rootNode) {
        const transf = currentUser.rootNode.replace('/', '>');
        cuentasDB = currentUser.userDb + '@' + transf;
      }
      return cuentasDB;
    } catch (error) {
      //error
    }
  };

  //Habilita el administrador de formularios a los usuarios de Galápago Agroconsultores
  const administadorFormularios = (userAF) => {
    return (
      userAF &&
      'emailVerified' in userAF &&
      userAF.emailVerified === true &&
      userAF.email.includes('@galapagoagro.co')
    );
  };
  //Vista del menu si es administrador de la cuenta
  const menuUserAdm = async (userState) => {
    try {
      const BDNR = await accountID(userState);
      const accountRef = databaseRef(defaultDb, `cuentas/${BDNR}/usuarios`);
      const snapshot = await getFromDB(accountRef);

      const userData = snapshot.val();
      const userid = userState.uid;

      const result = userData[userid];

      setMenuConfig({
        ...menuConfig,
        menuadm: result.admin_cuenta === 'Si' ? true : false,
        menuAdmForm:
          result.admin_cuenta === 'Si' && administadorFormularios(userState)
            ? true
            : false,
      });
    } catch (error) {
      setMenuConfig({ ...menuConfig, menuadm: false, menuAdmForm: false });
    }
  };

  // lista de cuentas desde el nodo cuentas
  const listAccount = async (currentUser) => {
    try {
      //BdNr basedatos nodoraiz
      const BDNR = await accountID(currentUser);
      const accountRef = databaseRef(defaultDb, `cuentas/${BDNR}`);
      const snapshot = await getFromDB(accountRef);
      const userData = snapshot.val();

      const bdcuentasusuarios = userData.usuarios;
      return bdcuentasusuarios;
    } catch (error) {
      //error
    }
  };

  // lista de cuentas desde el nodo usuario/uid
  const userAccounts = async (currentUser) => {
    try {
      const userRef = databaseRef(defaultDb, `usuarios/${currentUser.uid}`);
      const snapshot = await getFromDB(userRef);
      const userData = snapshot.val();

      if (userData.cuentas === undefined) {
        return '';
      }
      return userData.cuentas;
    } catch (error) {
      //error
    }
  };

  //Perfiles que tiene la cuenta
  const getprofiles = async (currentUser) => {
    try {
      //BdNr basedatos nodoraiz
      const BDNR = await accountID(currentUser);
      const accountRef = databaseRef(defaultDb, `cuentas/${BDNR}`);
      const snapshot = await getFromDB(accountRef);
      const userData = snapshot.val();

      const bdcuentasperfiles = userData.perfiles;
      return bdcuentasperfiles;
    } catch (error) {
      //error
    }
  };

  //Formularios que tiene la cuenta
  const getFormInfo = async () => {
    try {
      const formRef = userDB.ref('formularios/');
      const snapshot = await getFromDB(formRef);
      const userData = snapshot.val();

      return userData;
    } catch (error) {
      //error
    }
  };

  //Menú de la cuenta
  const getMenuInfo = async () => {
    try {
      const menuRef = userDB.ref('menu/');
      const snapshot = await getFromDB(menuRef);
      const userData = snapshot.val();

      return userData;
    } catch (error) {
      //error
    }
  };

  // Switch que recibe diferentes proveedores
  const signinwith = async () => {
    const provider = new GoogleAuthProvider();
    auth.useDeviceLanguage();
    const result = await signInWithPopup(auth, provider);
    const userRef = databaseRef(defaultDb, `usuarios/${result.user.uid}`);
    const snapshot = await getFromDB(userRef);
    const userData = snapshot.val();

    if (!userData) {
      setUserM(result);
      return result;
    }

    return result;
  };
  const recoveryPasword = async (email) => {
    await sendPasswordResetEmail(auth, email);
  };

  const login = async (email, password) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    return response;
  };

  const logout = () => {
    auth.signOut();
    setUser(null);
    store.remove('lastAccount');
    setIsAuthenticated(false);
    setMenuConfig({});
  };

  const signup = async (name, email, password) => {
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (response.user) {
        await updateProfile(response.user, { displayName: name });
      }

      await signInWithEmailAndPassword(auth, email, password);

      return response;
    } catch (error) {
      console.error('Error during signup:', error);
      throw error;
    }
  };

  /**
   * Retorna formularios de los agronegocios
   */
  const getForms = async () => {
    const forms = databaseRef(defaultDb, 'formularios');
    const snapshot = await getFromDB(forms);
    const value = snapshot.val();

    return Object.keys(value).reduce((acc, current) => {
      const item = value[current];

      return {
        ...acc,
        [item.agronegocio]: {
          ...acc[item.agronegocio],
          [item.tamaño]: item.configuracion,
        },
      };
    }, {});
  };

  const updateUser = (userData) => {
    setUser(userData);
  };

  // cambios usuario
  const confirm = async (email, password) => {
    return await signInWithEmailAndPassword(auth, email, password);
  };

  const editname = async (name) => {
    const dataUser = auth.currentUser;
    const displayName = name.nombre;

    await dataUser.updateProfile({ displayName: displayName });

    const changeName = httpsCallable(
      functions,
      'change_user_data_config_module'
    );
    const results = await changeName(name);

    return results;
  };

  const editnumber = async (number) => {
    const edituser = auth.currentUser;
    await edituser.updatephoneNumber(number);
  };

  const editemail = async (data) => {
    const edituser = auth.currentUser;
    const correo = edituser.email;
    const newCorreo = data.correo;
    const password = data.password;

    await confirm(correo, password);

    await edituser.updateEmail(newCorreo);

    const changeEmail = httpsCallable(
      functions,
      'change_user_data_config_module'
    );

    await changeEmail(data);
  };

  const editpassword = async (newpassword, confirmpassword) => {
    const edituser = auth.currentUser;
    const correo = edituser.email;

    const credential = EmailAuthProvider.credential(correo, confirmpassword);
    await reauthenticateWithCredential(edituser, credential);

    await updatePassword(edituser, newpassword);
  };

  /**
   * Creación de Empresa
   * @param {Object} companyData
   */

  const searchUser = async (data) => {
    const busqueda = httpsCallable(functions, 'search_email_admin_module');
    const results = await busqueda(data);
    return results;
  };

  //celular desde bd
  const celBD = async (uid) => {
    const userRef = databaseRef(defaultDb, `usuarios/${uid}`);
    const snapshot = await getFromDB(userRef);
    const searchUserDB = snapshot.val();
    return searchUserDB;
  };

  const addUserExist = async (userExist) => {
    //add_user_to_account
    const addUser = httpsCallable(functions, 'add_user_to_account');

    const results = await addUser(userExist);

    return results;
  };

  const AdmCreateAccount = async (data) => {
    const addUser = httpsCallable(functions, 'create_account_existing_user');

    const results = await addUser(data);

    return results;
  };

  const getBucketFiles = async () => {
    const storRef = storageRef(storage, '/');
    const res = await listAll(storRef);
    const resultFolder = [];

    res.items.forEach((itemRef) => {
      (async () => {
        const [path, meta] = await Promise.all([
          getDownloadURL(itemRef),
          getMetadata(itemRef),
        ]);

        const metaTags = meta.customMetadata ? meta.customMetadata.tags : '';

        const fileData = {
          name: itemRef.fullPath,
          searchTerm: `${itemRef.fullPath} ${metaTags}`,
          categori: 'img',
          img: path,
          size: meta.size,
          type: meta.contentType,
          created: meta.timeCreated,
          updated: meta.updated,
        };

        resultFolder.push(fileData);

      })();
    });

    return resultFolder;
  };


  const editFormAdmin = async (datauser) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }

    const editForm = httpsCallable(functions, 'update_form_admin_module');

    const edit = await editForm(datauser);

    return edit;
  };

  const createCompany = async (companyData) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }

    const createCompany = httpsCallable(functions, 'createCompany');

    const results = await createCompany(companyData);

    return results;
  };

  const createUserAdm = async (datauser) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }
    const createUser = httpsCallable(functions, 'create_user_admin_module');

    const results = await createUser(datauser);

    return results;
  };

  const editUserAdm = async (datauser) => {
    // if (isDev) {
    //   app.functions().useFunctionsEmulator('http://localhost:4000/');
    // }
    const editUser = httpsCallable(functions, 'update_user_admin_module');

    const results = await editUser(datauser);

    return results;
  };

  const inactiveAcountF = async (data) => {
    const deleteAcount = httpsCallable(functions, 'disable_user_admin_module');
    const inactive = await deleteAcount(data);

    return inactive;
  };
  const createProfileAdmin = async (data) => {
    const createProfile = httpsCallable(
      functions,
      'create_profile_admin_module'
    );
    const create = await createProfile(data);

    return create;
  };

  const editProfileAdmin = async (data) => {
    const editProfile = httpsCallable(functions, 'update_profile_admin_module');
    const edit = await editProfile(data);
    return edit;
  };

  const getCalendarUrl = async (data) => {
    const getCalendarCallable = httpsCallable(functions, 'get_calendar_url');
    return await getCalendarCallable(data);
  };

  const getAnalyticsUrl = async () => {
    let analyticsUrl = '';

    if (
      user &&
      menuConfig.menuAnalytics &&
      user.superset_activo &&
      user.superset_url !== ''
    ) {
      const token = await auth.currentUser
        .getIdToken()
        .catch(function (error) {});
      analyticsUrl = `${user.superset_url}login?token=${token}`;
    }

    return analyticsUrl;
  };

  const getToken = async () => {
    const token = await auth.currentUser
      .getIdToken()
      .catch(function (error) {});

    return token;
  };

  const callableFunction = async (nameFunction, data) => {
    // Timeout en ms según https://stackoverflow.com/questions/58313719/error-deadline-exceeded-when-calling-a-firebase-callable-cloud-function-onca
    const getResult = httpsCallable(functions, nameFunction, {
      timeout: 10 * 60 * 1000,
    });
    return await getResult({ ...data, uid: auth.currentUser.uid });
  };

  const generateMFACode = async (data) => {
    const getCode = httpsCallable(functions, 'generate_mfa_code');
    return await getCode(data);
  };
  const validateMFACode = async (data) => {
    const validateCode = httpsCallable(functions, 'validate_mfa_code');
    return await validateCode(data);
  };

  const getAccountById = async (data) => {
    const busqueda = httpsCallable(functions, 'get_account_by_id');
    const results = await busqueda(data);
    return results;
  };

  if (!isInitialized) return null;

  return (
    <FirebaseContext.Provider
      value={{
        user,
        userM,
        isAuthenticated,
        setIsAuthenticated,
        ...menuConfig,
        auth,
        login,
        logout,
        setUser,
        signup,
        db: userDB,
        defaultDb,
        analytics,
        storage,
        photoStorage,
        fileStorage,
        downloadsStorage,
        getForms,
        updateUser,
        createCompany,
        signinwith,
        recoveryPasword,
        menuUserAdm,
        getAnalyticsUrl,
        listAccount,
        getprofiles,
        getFormInfo,
        getBucketFiles,
        getMenuInfo,
        searchUser,
        createUserAdm,
        editUserAdm,
        editname,
        editnumber,
        editemail,
        editpassword,
        addUserExist,
        celBD,
        inactiveAcountF,
        userAccounts,
        AdmCreateAccount,
        initialize,
        changeAccountAdm,
        nameAccount,
        setNameAccount,
        createProfileAdmin,
        editFormAdmin,
        editProfileAdmin,
        getCalendarUrl,
        callableFunction,
        firestoredb,
        generateMFACode,
        validateMFACode,
        handleReauthenticateUser,
        setIsNewUserRegistered,
        isNewUserRegistered,
        getToken,
        getAccountById,
      }}
      {...props}
    />
  );
}

function useFirebase() {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
}

export { FirebaseProvider, useFirebase };



import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";


const storedLang = localStorage.getItem('i18nextLng');

if (!i18n.options.storedLang) {
  switch (i18n.language) {
    case 'es-ES':
      i18n.options.storedLang = 'es';
      break;
    case 'en-US':
      i18n.options.storedLang = 'en';
      break;
    case 'en-EN':
      i18n.options.storedLang = 'en';
      break;
    case 'pt-BR':
      i18n.options.storedLang = 'pt';
      break;
    default:
      i18n.options.storedLang = 'en';
      break;
  }
}

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
  backend:{
    loadPath: "/assets/translations/{{lng}}.json",
  },
  fallbackLng:'en',
  debug: false,
  ns:["global"],
  interpolation: {escapeValue: false},
  storedLang
});

export default i18n;
import React from 'react';
import PropTypes from 'prop-types';

import AppSnackbar from '../Snackbar/AppSnackbar';

function ToastMessages({ toasts, onClose }) {
  return toasts.map(({ id, type, message, open, duration, position }) => {
    const anchorOrigin = position.split(' ');
    return (
      <AppSnackbar
        key={id}
        id={`galapp-toast-${id}`}
        style={type}
        open={open}
        autoHideDuration={duration}
        anchorOrigin={{
          vertical: anchorOrigin[0],
          horizontal: anchorOrigin[1],
        }}
        onClose={(_, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          onClose(id);
        }}
      >
        {message}
      </AppSnackbar>
    );
  });
}

ToastMessages.defaultProps = {
  toasts: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ToastMessages;

import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'core/material-ui/theme';
import { FirebaseProvider } from 'core/context/firebase-context';
import { MenuProvider } from 'core/context/menu-context';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ToastProvider from 'core/context/toast';

import Routes from './routes';

const App = () => (
  <ThemeProvider theme={theme}>
    <FirebaseProvider>
      <MenuProvider>
        <DndProvider backend={HTML5Backend}>
          <ToastProvider>
            <Routes />
          </ToastProvider>
        </DndProvider>
      </MenuProvider>
    </FirebaseProvider>
  </ThemeProvider>
);

export default App;

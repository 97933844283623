import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const SnackbarComponent = ({
  children,
  onClose,
  open,
  style,
  autoHideDuration = 4000,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  ...props
}) => {
  return (
    <Snackbar
      id={props.id}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert onClose={onClose} severity={style}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;

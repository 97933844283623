import React, { createContext, useContext, useEffect, useState } from 'react';
import { get } from 'firebase/database';
import { useFirebase } from 'core/context/firebase-context';
import Loading from 'core/components/Loading';

const MenuContext = createContext();

function MenuProvider(props) {
  const { user, db } = useFirebase();
  const [menu, setMenu] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState('Inicio');
  const [allowedMenu, setAllowedMenu] = useState(['home']);
  const [isChange, setIsChange] = useState(false);

  const getMenuByProfile = (menuList) => {
    const menuProfile = menuList.filter(
      (menu) =>
        menu.perfiles[user.profile] &&
        menu.perfiles[user.profile].read
    );

    user.profile === 'inactivo'
      ? setAllowedMenu(['home'])
      : setAllowedMenu(['home', ...menuProfile.map((el) => el.key)]);

    return menuProfile;
  };

  const sortMenuList = (list) => {
    let ordenList = list.filter((el) => el.orden);
    let noOrdenList = list.filter((el) => !el.orden);
    const sortAlphabet = noOrdenList.sort((a, b) =>
      a.key.toUpperCase() > b.key.toUpperCase() ? 1 : -1
    );
    const filterList = ordenList.map((el) => {
      if (el.orden) return { ...el, orden: Number(el.orden) };

      return el;
    });

    const sortList = filterList.sort((a, b) => a.orden - b.orden);
    const menuOrdered = [...sortList, ...sortAlphabet];

    setMenu(menuOrdered);
    getMenuByProfile(menuOrdered);
  };

  /**
   * Obtiene los datos de Firebase database y los asigna
   * al estado del menu.
   */
  async function getMenuData() {
    try {
      setLoading(true);

      if (db) {
        const menuRef = db.ref('menu/');
        const menuSnapshot = await get(menuRef);

        let menuList = [];
        menuSnapshot.forEach((child) => {
          menuList.push({ ...child.val(), key: child.key });
        });
        sortMenuList(menuList);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching menu data:', error);
    }
  }

  useEffect(() => {
    getMenuData();
  }, [db, isChange]);

  if (isLoading) return <Loading />;

  return (
    <MenuContext.Provider
      value={{
        menu,
        activeMenu,
        setActiveMenu,
        setIsChange,
        allowedMenu,
        getMenuData,
        isChange,
      }}
      {...props}
    />
  );
}

function useMenu() {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
}

export { MenuProvider, useMenu };

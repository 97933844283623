
let settings;

let dev =
{
  apiKey: 'AIzaSyAph6qsAzhLrZGwwzBT82507YOI7q6TVa0',
  authDomain: 'galapp-6b902.firebaseapp.com',
  projectId: 'galapp-6b902',
  databaseURL: 'https://galapp-6b902.firebaseio.com',
  storageBucket: 'galapp-6b902.appspot.com',
  messagingSenderId: '1067914327266',
  appId: '1:1067914327266:web:1cb4cf867211a3ac',
  measurementId: 'G-BVRG033PHF'
};

let prod = {
  apiKey: 'AIzaSyA-qMkBSRwY0biae_jqMhEZFtyUPGbctPU',
  authDomain: 'appfinca-4cc7a.firebaseapp.com',
  databaseURL: 'https://appfinca-4cc7a.firebaseio.com',
  projectId: 'appfinca-4cc7a',
  storageBucket: 'appfinca-4cc7a.appspot.com',
  messagingSenderId: '487630576275',
  appId: '1:487630576275:web:9b2c77850e15cb3f',
  measurementId: 'G-QF882RQ7QG',
};

const isDev = (variable) => {
  return variable in process.env && process.env[variable].trim() === 'development';
};

if (isDev("NODE_ENV") || isDev("REACT_APP_GALAPP_ENV")) {
  settings = dev;
} else {
  settings = prod;
}

export default settings;